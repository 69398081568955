<template>
  <div class="border-t border-neutral-200 pt-5">
    <div class="flex justify-between">
      <div class="text-xl font-bold capitalize">
        {{ $t(booking.isCancelled ? 'totalCancellationCharge' : 'total') }}
      </div>

      <BookingConfirmationSummaryTotalRate v-if="!shouldShowSubtotal" />
    </div>

    <div v-if="shouldShowSubtotal" class="mt-4">
      <div class="mb-2 flex justify-between">
        <div class="font-bold capitalize">{{ $t('subtotal') }}</div>

        <div>
          {{ generateAmountDisplayText(booking.totalAmountBeforeTax) }}
        </div>
      </div>

      <div class="flex flex-col gap-1">
        <div
          v-if="bookingVat && bookingVat.includedAmount > 0"
          class="first-letter:capitalize"
        >
          {{
            $t('includesNamedTaxOfAmount', {
              taxName: bookingVat.vat.name,
              amount: generateAmountDisplayText(bookingVat.includedAmount),
            })
          }}

          <BookingConfirmationSummaryTotalTaxRegistrationNumber
            v-if="bookingVat.vat.registrationNumber"
            :registration-number="bookingVat.vat.registrationNumber"
          />
        </div>

        <div
          v-for="({ tax, amount }, index) of bookingAdditionalTaxesNotToItemize"
          :key="index"
          class="first-letter:capitalize"
        >
          {{
            $t(
              tax.rate.inclusionType === AdditionalTaxRateInclusionType.Include
                ? 'includesNamedTaxOfAmount'
                : 'excludesNamedTaxOfAmount',
              {
                taxName: useAdditionalTaxNameDisplayText(tax).value,
                amount: generateAmountDisplayText(amount),
              },
            )
          }}

          <BookingConfirmationSummaryTotalTaxRegistrationNumber
            v-if="tax.registrationNumber"
            :registration-number="tax.registrationNumber"
          />

          <div class="text-sm">
            {{ generateAdditionalTaxRateDisplayText(tax.rate) }}
          </div>
        </div>
      </div>

      <div
        v-if="bookingAdditionalTaxesToItemize.length > 0"
        class="mt-2 flex flex-col gap-2"
      >
        <div
          v-for="({ tax, amount }, index) of bookingAdditionalTaxesToItemize"
          :key="index"
        >
          <div class="flex justify-between gap-2">
            <div>
              <span class="font-bold capitalize">
                {{ useAdditionalTaxNameDisplayText(tax).value }}
              </span>

              <BookingConfirmationSummaryTotalTaxRegistrationNumber
                v-if="tax.registrationNumber"
                :registration-number="tax.registrationNumber"
              />

              <div class="text-sm">
                {{ generateAdditionalTaxRateDisplayText(tax.rate) }}
              </div>
            </div>

            <div>{{ generateAmountDisplayText(amount) }}</div>
          </div>
        </div>
      </div>

      <div
        v-if="bookingVat && bookingVat.excludedAmount > 0"
        class="mt-2 flex justify-between gap-2 capitalize"
      >
        <div class="font-bold">
          {{
            $t('excludedNamedTax', {
              taxName: bookingVat.vat.name,
            })
          }}
        </div>

        <div>{{ generateAmountDisplayText(bookingVat.excludedAmount) }}</div>
      </div>

      <BookingConfirmationSummaryTotalRate class="mt-4 text-right" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  hasAnyBookingTaxes,
  groupBookingAdditionalTaxesByInclusionTypeFromBookingTaxes,
} from '@/booking/booking-taxes/booking-taxes.utilities';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationSummaryTotalRate from '@/booking-confirmation-page/summary/total/BookingConfirmationSummaryTotalRate.vue';
import BookingConfirmationSummaryTotalTaxRegistrationNumber from '@/booking-confirmation-page/summary/total/BookingConfirmationSummaryTotalTaxRegistrationNumber.vue';
import { useAdditionalTaxNameDisplayText } from '@/tax/additional/name-display-text/additional-tax-name-display-text.composable';
import type { AdditionalTaxRate } from '@/tax/additional/rate/additional-tax-rate';
import { AdditionalTaxRateInclusionType } from '@/tax/additional/rate/additional-tax-rate';
import { useAdditionalTaxRateDisplayText } from '@/tax/additional/rate/display-text/additional-tax-rate-display-text.composable';

const { n } = useI18n();

const { property, booking } = storeToRefs(useBookingConfirmationStore());

const bookingTaxes = computed(() => booking.value.bookingTaxes);

const bookingVat = computed(() => bookingTaxes.value.bookingVat);

const shouldShowSubtotal = computed(() =>
  hasAnyBookingTaxes(bookingTaxes.value),
);

const groupedBookingAdditionalTaxes = computed(() =>
  groupBookingAdditionalTaxesByInclusionTypeFromBookingTaxes(
    bookingTaxes.value,
  ),
);

const bookingAdditionalTaxesNotToItemize = computed(() => [
  ...groupedBookingAdditionalTaxes.value[
    AdditionalTaxRateInclusionType.Include
  ],
  ...groupedBookingAdditionalTaxes.value[
    AdditionalTaxRateInclusionType.Exclude
  ],
]);

const bookingAdditionalTaxesToItemize = computed(
  () =>
    groupedBookingAdditionalTaxes.value[
      AdditionalTaxRateInclusionType.IncludeInvoiceOnly
    ],
);

const generateAmountDisplayText = (amount: number): string =>
  n(amount, {
    style: 'currency',
    currency: property.value.currencyCode,
  });

const generateAdditionalTaxRateDisplayText = (
  additionalTaxRate: AdditionalTaxRate,
): string => useAdditionalTaxRateDisplayText(additionalTaxRate, property).value;
</script>
