<template>
  <BookingConfirmationAssurance v-if="!hasMismatchedUnitStayDates">
    <i18n-t
      :keypath="
        booking.arrivalTime
          ? 'yourStayStartsOnDateWithExpectedArrivalTime'
          : 'yourStayStartsOnDate'
      "
      tag="div"
      scope="global"
      :plural="stayLength"
    >
      <template #stayLength>{{ stayLength }}</template>

      <template #checkInDate>
        <span class="font-bold">
          {{
            $d(createDateObject(bookingStayDates.checkInDate), {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          }}
        </span>
      </template>

      <template #arrivalTime>
        <span v-if="booking.arrivalTime" class="font-bold">
          {{ generateTimeDisplayText(booking.arrivalTime) }}
        </span>
      </template>
    </i18n-t>
  </BookingConfirmationAssurance>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import BookingConfirmationAssurance from '@/booking-confirmation-page/assurances/BookingConfirmationAssurance.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import { createDateObject } from '@/date/date.utilities';
import { getStayLengthFromStayDates } from '@/stay-dates/stay-dates.utilities';
import type { Time } from '@/time/time';
import { useTimeDisplayText } from '@/time/time-display-text.composable';

const { bookingStayDates, booking, hasMismatchedUnitStayDates } = storeToRefs(
  useBookingConfirmationStore(),
);

const stayLength = computed(() =>
  getStayLengthFromStayDates(bookingStayDates.value),
);

const generateTimeDisplayText = (time: Time): string =>
  useTimeDisplayText(time).value;
</script>
