<template>
  <BookingConfirmationAssurance
    v-if="
      freeCancellationStatus.isFreeCancellation &&
      !booking.isAnyActiveBookingUnitNonRefundable
    "
  >
    <i18n-t
      keypath="freeCancellationUntilDate"
      tag="div"
      scope="global"
      class="first-letter:capitalize"
    >
      <template #date>
        <span class="font-bold">
          {{
            $d(
              createDateObject(
                freeCancellationStatus.freeCancellationCutOffDate,
              ),
              {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              },
            )
          }}
        </span>
      </template>
    </i18n-t>
  </BookingConfirmationAssurance>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import BookingConfirmationAssurance from '@/booking-confirmation-page/assurances/BookingConfirmationAssurance.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import { createDateObject } from '@/date/date.utilities';

const { booking, bookingPolicyOverStay } = storeToRefs(
  useBookingConfirmationStore(),
);

const freeCancellationStatus = computed(
  () => bookingPolicyOverStay.value.freeCancellationStatus,
);
</script>
