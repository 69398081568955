import type { AbandonedCart } from '@/abandoned-cart/abandoned-cart';
import { ky } from '@/ky';

export const sendAbandonedCart = async (
  abandonedCart: AbandonedCart,
  widgetId: string,
  propertyId: number,
): Promise<void> => {
  try {
    await ky.post(`widgets/${widgetId}/property/${propertyId}/abandoned_cart`, {
      json: abandonedCart,
    });
  } catch (error) {
    return undefined;
  }
};
