import { createInjectionState } from '@vueuse/core';
import { computed, ref } from 'vue';

interface ValidationGroup {
  id: string;
  validate: () => boolean;
  errorMessages: Readonly<string[]>;
  container: HTMLElement | null;
}

const [useProvideForm, useMaybeForm] = createInjectionState(() => {
  const validationGroups = ref<ValidationGroup[]>([]);

  const canShowErrorMessages = ref(false);

  const errorMessages = computed(() =>
    validationGroups.value.map(({ errorMessages }) => errorMessages).flat(),
  );

  const register = (validationGroup: ValidationGroup) => {
    validationGroups.value.push(validationGroup);
  };

  const unregister = (id: string) => {
    validationGroups.value = validationGroups.value.filter(
      (validationGroup) => validationGroup.id !== id,
    );
  };

  const validate = () => {
    canShowErrorMessages.value = true;

    let hasScrolledToInvalidValidationGroup = false;

    for (const validationGroup of validationGroups.value) {
      const isValid = validationGroup.validate();

      if (!isValid && !hasScrolledToInvalidValidationGroup) {
        validationGroup.container?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

        hasScrolledToInvalidValidationGroup = true;
      }
    }
  };

  return {
    canShowErrorMessages,
    errorMessages,
    unregister,
    register,
    validate,
  };
});

const useForm = () => {
  const form = useMaybeForm();

  if (!form) {
    throw new Error(
      'Please call `useProvideForm` on the appropriate parent component',
    );
  }

  return form;
};

export { useForm, useProvideForm };
