<template>
  <div
    v-if="booking.activeBookingUnits.length > 0"
    class="mb-2 text-xl font-bold capitalize"
  >
    {{
      $t('unitsBooked', { unitTypeGender, unitTypeName: unitTypeNamePlural })
    }}
  </div>

  <ul>
    <li
      v-for="(bookingUnit, index) in booking.activeBookingUnits"
      :key="index"
      class="mb-5"
    >
      <BookingConfirmationSummaryUnit :booking-unit="bookingUnit" />
    </li>
  </ul>

  <div
    v-if="booking.cancelledBookingUnits.length > 0"
    class="mb-2 text-xl font-bold capitalize"
  >
    {{ $t('unitsCancelled', { unitTypeName: unitTypeNamePlural }) }}
  </div>

  <ul>
    <li
      v-for="(cancelledBookingUnit, index) in booking.cancelledBookingUnits"
      :key="index"
      class="mb-5"
    >
      <BookingConfirmationSummaryCancelledUnit
        :cancelled-booking-unit="cancelledBookingUnit"
      />
    </li>
  </ul>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationSummaryCancelledUnit from '@/booking-confirmation-page/summary/unit/BookingConfirmationSummaryCancelledUnit.vue';
import BookingConfirmationSummaryUnit from '@/booking-confirmation-page/summary/unit/BookingConfirmationSummaryUnit.vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';

const { property, booking } = storeToRefs(useBookingConfirmationStore());

const { unitTypeGender, unitTypeNamePlural } = useUnitType(
  computed(() => property.value.unitType),
);
</script>
