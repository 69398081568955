<template>
  <div
    class="flex justify-center p-4 capitalize text-sky-500 underline print:hidden"
  >
    <AppLinkButton
      :text="toTitleCase($t('printBookingConfirmation'))"
      @click="print"
    >
      <template #icon>
        <IconPrinter :size="28" />
      </template>
    </AppLinkButton>
  </div>
</template>
<script setup lang="ts">
import { IconPrinter } from '@tabler/icons-vue';
import { useLocaleString } from '@/string/locale-string.composable';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';

const { toTitleCase } = useLocaleString();

const print = window.print;
</script>
