import type { ComputedRef } from 'vue';
import { computed, reactive } from 'vue';
import type { PropertyAvailability } from '@/availability/property-availability/property-availability';
import {
  findPropertyAvailabilityUnitAvailabilityByDateOrFail,
  getPropertyAvailabilityStayDates,
} from '@/availability/property-availability/property-availability.utilities';
import { isPseudoUnitAvailable } from '@/availability/pseudo-unit-availability/pseudo-unit-availability.utilities';
import type { UnitAvailability } from '@/availability/unit-availability/unit-availability';
import type { UnitItineraryItem } from '@/booking-itinerary/booking-itinerary';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import {
  CodeResourceType,
  type CodeResource,
} from '@/code/resource/code-resource';
import { findPrivateRateOverrideWayToSellById } from '@/private-rate-override/private-rate-override.utilities';
import type { Unit } from '@/property/unit/unit';
import type { WayToSell } from '@/property/way-to-sell/way-to-sell';
import { isWayToSellApplicableOnStayDates } from '@/property/way-to-sell/way-to-sell.utilities';
import {
  getNightsOfStayFromStayDates,
  getStayLengthFromStayDates,
} from '@/stay-dates/stay-dates.utilities';

export const useUnitOverStay = (
  unit: Unit,
  propertyAvailability: PropertyAvailability,
  codeResource?: CodeResource | undefined,
) => {
  const bookingItineraryStore = useBookingItineraryStore();

  const stayDates = computed(() =>
    getPropertyAvailabilityStayDates(propertyAvailability),
  );

  const stayLength = computed(() =>
    getStayLengthFromStayDates(stayDates.value),
  );

  const nightsOfStay = computed(() =>
    getNightsOfStayFromStayDates(stayDates.value),
  );

  const unitAvailabilityForNightsOfStay = computed(() =>
    nightsOfStay.value.map((nightOfStay) =>
      findUnitAvailabilityByDate(nightOfStay),
    ),
  );

  const unitAvailabilityForCheckInDate = computed(
    () => unitAvailabilityForNightsOfStay.value[0]!,
  );

  const minimumStay = computed(
    () => unitAvailabilityForCheckInDate.value.minimumStay,
  );

  const isClosedOut = computed(() =>
    unitAvailabilityForNightsOfStay.value.some(
      ({ isClosedOut }) => isClosedOut,
    ),
  );

  const isSoldOut = computed(() => numberAvailable.value <= 0);

  const hasRates = computed(() =>
    unitAvailabilityForNightsOfStay.value.every(({ rate }) => rate !== 0),
  );

  const violatesMinimumStay = computed(
    () => stayLength.value < minimumStay.value,
  );

  const onlyViolatesMinimumStay = computed(
    () =>
      violatesMinimumStay.value &&
      !isClosedOut.value &&
      !isSoldOut.value &&
      hasRates.value,
  );

  const minimumAllocation = computed(() =>
    Math.min(
      ...unitAvailabilityForNightsOfStay.value.map(
        ({ allocation }) => allocation,
      ),
    ),
  );

  const pseudoUnitIds = computed(
    () =>
      new Set(
        unitAvailabilityForNightsOfStay.value.flatMap(
          ({ pseudoUnitAvailabilities }) =>
            pseudoUnitAvailabilities.map(({ pseudoUnitId }) => pseudoUnitId),
        ),
      ),
  );

  const numberOfPseudoUnits = computed(() => pseudoUnitIds.value.size);

  const hasPseudoUnits = computed(() => numberOfPseudoUnits.value > 0);

  const unavailablePseudoUnitIds = computed(
    () =>
      new Set(
        unitAvailabilityForNightsOfStay.value.flatMap(
          ({ pseudoUnitAvailabilities }) =>
            pseudoUnitAvailabilities
              .filter(
                (pseudoUnitAvailability) =>
                  !isPseudoUnitAvailable(pseudoUnitAvailability),
              )
              .map(({ pseudoUnitId }) => pseudoUnitId),
        ),
      ),
  );

  const numberOfUnavailablePseudoUnitIds = computed(
    () => unavailablePseudoUnitIds.value.size,
  );

  const numberOfAvailablePseudoUnits = computed(
    () => numberOfPseudoUnits.value - numberOfUnavailablePseudoUnitIds.value,
  );

  const numberAvailable = computed(() =>
    hasPseudoUnits.value
      ? Math.min(minimumAllocation.value, numberOfAvailablePseudoUnits.value)
      : minimumAllocation.value,
  );

  const isAvailable = computed(
    () =>
      !isClosedOut.value &&
      !isSoldOut.value &&
      hasRates.value &&
      !violatesMinimumStay.value,
  );

  const unitItineraryItems: ComputedRef<UnitItineraryItem[]> = computed(() =>
    bookingItineraryStore.unitItinerary.filter(
      ({ unitId }) => unitId === unit.id,
    ),
  );

  const numberSelected = computed(() => unitItineraryItems.value.length);

  const numberAvailableAfterSelection = computed(
    () => numberAvailable.value - numberSelected.value,
  );

  const availableWaysToSell = computed(() =>
    unit.waysToSell.filter(
      (wayToSell) =>
        !shouldCodeResourceExcludeWayToSell(wayToSell.id) &&
        isWayToSellApplicableOnStayDates(
          wayToSell,
          propertyAvailability,
          stayDates.value,
        ),
    ),
  );

  const findAvailableWayToSellById = (
    wayToSellId: number,
  ): WayToSell | undefined =>
    availableWaysToSell.value.find(({ id }) => id == wayToSellId);

  const findUnitAvailabilityByDate = (date: string): UnitAvailability =>
    findPropertyAvailabilityUnitAvailabilityByDateOrFail(
      propertyAvailability,
      unit.id,
      date,
    );

  const shouldCodeResourceExcludeWayToSell = (wayToSellId: number): boolean =>
    codeResource && codeResource.type === CodeResourceType.PrivateRateOverride
      ? !findPrivateRateOverrideWayToSellById(
          codeResource.privateRateOverride,
          wayToSellId,
        )
      : false;

  return reactive({
    unit,
    isAvailable,
    minimumStay,
    onlyViolatesMinimumStay,
    numberAvailable,
    numberAvailableAfterSelection,
    availableWaysToSell,
    findAvailableWayToSellById,
  });
};
