<template>
  <div class="mb-2 text-xl font-bold capitalize">{{ $t('getInTouch') }}</div>

  <div class="break-anywhere grid grid-cols-[min-content_auto] gap-2">
    <span><IconPhone :size="22" /></span>
    <span>
      <a
        class="underline hover:no-underline"
        :href="`tel:${property.phoneNumber}`"
      >
        {{ property.phoneNumber }}
      </a>
    </span>

    <span><IconAt :size="22" /></span>
    <span>
      <a
        class="underline hover:no-underline"
        :href="`mailto:${property.emailAddress}`"
      >
        {{ property.emailAddress }}
      </a>
    </span>

    <span><IconMapPin :size="22" /></span>
    <FormattedAddress :address="property.address" />
  </div>
</template>
<script setup lang="ts">
import { IconAt, IconMapPin, IconPhone } from '@tabler/icons-vue';
import { storeToRefs } from 'pinia';
import FormattedAddress from '@/address/formatted/FormattedAddress.vue';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';

const { property } = storeToRefs(useBookingConfirmationStore());
</script>
